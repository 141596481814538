var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tab-pane show active",
    class: {
      active: !_vm.$auth.isAdmin
    },
    attrs: {
      "id": `tab-company-${_vm.supplier._id}`,
      "role": "tabpanel"
    }
  }, [_c('h4', {
    staticClass: "mb-1"
  }, [_vm.$auth.isAdmin ? _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.company.name))]) : _vm._e(), _vm._v(" "), _c('br'), _vm._v("Company Settings ")]), _c('p', [_vm._v("These settings will be the default for all accounts within your company. Account-level settings will override these.")]), !_vm.companyOverride ? _c('a', {
    staticClass: "font-w600",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('update-company-override', true);
      }
    }
  }, [_c('div', {
    staticClass: "block block-rounded bg-light text-center p-3"
  }, [_vm._v("Currently using supplier defaults, click here to override.")])]) : _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('PortalForm', {
    attrs: {
      "data": _vm.companyForm,
      "error": _vm.error
    },
    on: {
      "update:data": function (companyForm) {
        return _vm.$emit('update:companyForm', companyForm);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "col-md-8"
  }, [_c('button', {
    staticClass: "btn btn-primary mb-3 mr-3",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickUpdateCompany.apply(null, arguments);
      }
    }
  }, [_vm.loadingAction.updateSupplierPortal ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update Company")])]), _c('button', {
    staticClass: "btn btn-danger mb-3",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickClearCompany.apply(null, arguments);
      }
    }
  }, [_vm.loadingAction.deleteSupplierPortal ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Clearing...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Clear Settings")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }